<template>
	<v-card class="mx-auto" max-width="900" outlined>
		<v-row class="ma-0 pa-0 d-flex flex-row">
			<v-col cols="3" class="pa-0">
				<div class="image-wrapper d-flex align-center justify-center">
					<v-img
						:src="forum.banner.publicURL"
						v-if="forum.banner !== null"
					></v-img>

					<p v-else class="font-weight-bold">No Image Uploaded</p>
				</div>
			</v-col>
			<v-col cols="8" class="px-4 py-3">
				<div class="flex-column align-start justify-space-between">
					<h3 class="title" color="#5F615A">
						{{ forum.title }}
					</h3>
					<h4
						class="font-weight-light description mb-2"
						v-if="forum.description"
					>
						{{ forum.description }}
					</h4>
					<div class="d-flex flex-column">
						<a class="link">{{ forum.externalSiteLink }}</a>
					</div>
				</div>
			</v-col>
			<span
				class="mdi mdi-pencil edit mr-3"
				@click="$emit('edit-forum', forum)"
			></span>
			<span
				class="mdi mdi-close-circle remove mr-3"
				@click="$emit('remove-forum', forum.id)"
			></span>
		</v-row>
	</v-card>
</template>
<script>
export default {
	props: {
		forum: {
			type: Object,
		},
	},
	components: {},
}
</script>
<style scoped>
.section-heading {
	font-size: 1.2rem;
}
.remove {
	font-size: 1.5rem;
	color: #f44336;
	cursor: pointer;
}
.edit {
	font-size: 1.3rem;
	color: var(--blue);
	cursor: pointer;
}
.image-wrapper {
	overflow: hidden;
	border: 1px solid #eee;
	max-height: 200px !important;
	height: 100%;
}
.image-wrapper .v-responsive {
	height: 100% !important;
}
.title a {
	font-size: 21px !important;
	line-height: 1.4 !important;
	color: black !important;
	text-decoration: none !important;
}
.title a:hover {
	color: var(--blue) !important;
}
.description {
	font-size: 18px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.link {
	font-size: 17px;
	font-weight: 400;
	color: var(--blue);
}
.link:hover {
	text-decoration: underline;
}
.date {
	font-size: 15px;
	font-weight: 400;
	color: #808080eb;
}
</style>
