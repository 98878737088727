<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<div class="mb-4 text-right">
			<v-btn depressed class="add" @click="addForum()">
				+ Add Forum
			</v-btn>
		</div>

		<v-card v-if="forums.length > 0" class="py-6 px-6">
			<draggable v-model="forums" class="drag-area" ghost-class="ghost">
				<transition-group name="sortable">
					<ForumListing
						v-for="forum in forums"
						:key="forum.id"
						:forum="forum"
						@edit-forum="editForum($event)"
						@remove-forum="removeForum($event)"
					></ForumListing>
				</transition-group>
			</draggable>
			<v-navigation-drawer
				v-model="drawer"
				fixed
				stateless
				temporary
				right
				class="forum-drawer"
				v-if="showCreateForum"
			>
				<div class="d-flex align-center header pt-2 px-2 pb-2">
					<span
						class="mdi mdi-chevron-left back-icon"
						@click="closeDrawer()"
					></span>
					<h1 class="crud-title ml-2">Add Forum</h1>
					<span
						class="mdi mdi-close back-icon"
						@click="closeDrawer()"
					></span>
				</div>
				<CreateForum
					:forum="forum"
					@save-forum="updateForums($event)"
					class="px-4"
				></CreateForum>
			</v-navigation-drawer>
		</v-card>
		<NoData v-else></NoData>
	</v-container>
</template>

<script>
import draggable from 'vuedraggable'
import CreateForum from './components/CreateForum'
import ForumListing from './components/ForumListing'
import NoData from '../../../components/NoData'
import { v4 as uuidv4 } from 'uuid'
export default {
	data() {
		return {
			drawer: true,
			showCreateForum: false,
			forums: [],
			forum: {},
		}
	},
	components: {
		draggable,
		CreateForum,
		ForumListing,
		NoData,
	},
	created() {
		this.getForumData()
	},

	methods: {
		addForum() {
			this.forum = {
				banner: '',
				title: '',
				description: '',
				datePublished: '',
				externalSiteLink: '',
				id: uuidv4(),
			}
			let arr = this.forums
			arr.push(this.forum)
			this.forums = arr
			this.showCreateForum = true
		},
		updateForums(emittedObj) {
			let forums = this.forums
			forums.every(function (forum, index) {
				if (forum.id === emittedObj.id) {
					let obj = {
						banner: emittedObj.banner ? emittedObj.banner : null,
						title: emittedObj.title,
						description: emittedObj.description,
						datePublished: emittedObj.datePublished,
						externalSiteLink: emittedObj.externalSiteLink,
						id: emittedObj.id,
					}
					forums[index] = obj
					// vm.$set(vm.facilities, index, obj);
					return false // to break the loop
				} else {
					return true
				}
			})
			this.forums = forums
			this.showCreateForum = false
			this.saveForum()
		},
		removeForum(forumId) {
			let arr = this.forums
			arr.forEach((forum, index) => {
				if (forum.id === forumId) {
					arr.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.forums = arr
			this.saveForum()
		},
		editForum(forum) {
			this.showCreateForum = true
			this.forum = forum
		},
		getForumData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/pages/forum`,
			}).then((response) => {
				let forums = response.data.meta.forums

				this.forums = forums.map((forum) => ({
					banner: forum.banner ? forum.banner : null,
					title: forum.title,
					description: forum.description,
					datePublished: forum.datePublished,
					externalSiteLink: forum.externalSiteLink,
					id: uuidv4(),
				}))
			})
		},
		saveForum() {
			this.loading = true
			const projectId = this.$store.state.app.projectId

			let payload = {
				forums: this.forums.map((forum) => ({
					banner: forum.banner ? forum.banner.media : null,
					title: forum.title,
					description: forum.description,
					datePublished: forum.datePublished,
					externalSiteLink: forum.externalSiteLink,
				})),
			}

			this.axios({
				method: 'put',
				url: `/${projectId}/pages/forum`,
				data: payload,
			})
				.then((res) => {
					let forums = res.data.data.meta.forums
					this.forums = forums.map((forum) => ({
						banner: forum.banner,
						title: forum.title,
						description: forum.description,
						datePublished: forum.datePublished,
						externalSiteLink: forum.externalSiteLink,
						id: uuidv4(),
					}))
					this.loading = false
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.loading = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		closeDrawer() {
			this.showCreateForum = !this.showCreateForum
			if (this.forum.title === '') {
				this.removeForum(this.forum.id)
			}
		},
	},
}
</script>

<style scoped>
.forum-drawer {
	width: 550px !important;
}
.add {
	background-color: var(--blue) !important;
	color: white;
}
.date {
	font-size: 15px;
	font-weight: 400;
	color: #808080eb;
}
.crud-title {
	width: 100% !important;
}
.back-icon {
	font-size: 25px;
	cursor: pointer;
}
</style>
